<i18n src="./resources/locales.json"></i18n>

<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <span v-if="open" class="opened-section-name-color">
          {{ sectionName }}
        </span>
        <span v-else>
          {{ sectionName }}
        </span>
      </template>
    </v-expansion-panel-header>
    <v-sheet class="list-content-background-color">
      <v-expansion-panel-content
        class="list-content-background-color content-padding pb-0"
        eager
      >
        <v-container class="mt-3">
          <v-row class="list-content-background-color">
            <p class="mb-0">
              <span class="font-weight-bold primary--text"
                >{{ $t('total') }} </span
              >{{ getTotalSubService() | currency('BOB') }}
            </p>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
      <v-expansion-panel-content
        :style="getSectionStyle()"
        class="align-self-start overflow-y-auto section-height-width list-content-background-color content-padding"
        v-scroll.self="() => {}"
        eager
      >
        <v-form ref="servicesSectionForm" v-model="isValid">
          <v-container>
            <v-row>
              <v-sheet class="width-section list-content-background-color">
                <v-sheet
                  v-for="service in servicesToBeSelected"
                  :key="service.id"
                  class="list-content-background-color"
                >
                  <v-row class="mb-n7">
                    <v-col cols="1">
                      <div
                        v-if="isServiceAssignedToWorker(service)"
                        class="mt-2"
                      >
                        <v-menu
                          :close-on-content-click="true"
                          top
                          eager
                          open-on-hover
                          close-delay="100"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon color="grey lighten-1">
                                mdi-alert
                              </v-icon>
                            </v-btn>
                          </template>

                          <v-card max-width="290px">
                            <v-card-text
                              v-if="isFinishedAssignedService(service)"
                            >
                              {{ getFinishedJobMessageForService(service) }}
                            </v-card-text>

                            <v-card-text v-else>
                              {{
                                $t(
                                  'thisWorkAlreadyHasAssignedWorkersPleaseUnassignThemToEditIt'
                                )
                              }}
                            </v-card-text>

                            <v-divider
                              v-if="!isFinishedAssignedService(service)"
                            ></v-divider>

                            <v-card-actions
                              v-if="!isFinishedAssignedService(service)"
                            >
                              <v-spacer></v-spacer>

                              <v-btn
                                color="primary"
                                @click="
                                  openConfirmationModalForService(service)
                                "
                              >
                                {{ $t('unassign') }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-checkbox
                        v-model="service.isChecked"
                        :label="service.name"
                        @change="resetValidations"
                        hide-details
                        :disabled="isServiceAssignedToWorker(service)"
                        class="py-0 ml-n14"
                      />
                    </v-col>
                    <v-col cols="3" class="py-0 mt-3 ml-n13">
                      <v-text-field
                        v-if="service.subServices.length === 1"
                        :label="$t('price')"
                        :rules="[
                          value =>
                            isValidPriceForService(service, value) ||
                            $t('priceIsRequiredForChosenService'),
                          value =>
                            isPositivePriceForService(service, value) ||
                            $t('priceMustBePositiveForChosenService')
                        ]"
                        required
                        v-model="service.price"
                        type="number"
                        class="pl-12 ma-0"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-container
                    v-if="service.isChecked && service.subServices.length > 1"
                    class="pl-8"
                  >
                    <v-sheet
                      v-for="subService in service.subServices"
                      :key="subService.id"
                      class="list-content-background-color"
                    >
                      <v-row>
                        <v-col cols="1">
                          <div
                            v-if="
                              isSubServiceAssignedToWorker(
                                service,
                                subService.id
                              )
                            "
                            class="mt-3"
                          >
                            <v-row
                              v-if="
                                subService.id === 'other' ||
                                  subService.id === 'other1' ||
                                  isFirstOther(
                                    service.subServices,
                                    subService.id
                                  )
                              "
                              style="margin-top: 80%;"
                            >
                            </v-row>
                            <v-menu
                              :close-on-content-click="true"
                              top
                              eager
                              open-on-hover
                              close-delay="100"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon color="grey lighten-1">
                                    mdi-alert
                                  </v-icon>
                                </v-btn>
                              </template>

                              <v-card max-width="290px">
                                <v-card-text
                                  v-if="
                                    isFinishedAssignedSubService(
                                      service,
                                      subService.id
                                    )
                                  "
                                >
                                  {{
                                    getFinishedJobMessageForSubService(
                                      service,
                                      subService.id
                                    )
                                  }}
                                </v-card-text>
                                <v-card-text v-else>
                                  {{
                                    $t(
                                      'thisWorkAlreadyHasAssignedWorkersPleaseUnassignThemToEditIt'
                                    )
                                  }}
                                </v-card-text>

                                <v-divider
                                  v-if="
                                    !isFinishedAssignedSubService(
                                      service,
                                      subService.id
                                    )
                                  "
                                ></v-divider>

                                <v-card-actions
                                  v-if="
                                    !isFinishedAssignedSubService(
                                      service,
                                      subService.id
                                    )
                                  "
                                >
                                  <v-spacer></v-spacer>

                                  <v-btn
                                    color="primary"
                                    @click="
                                      openConfirmationModalForSubService(
                                        service,
                                        subService.id
                                      )
                                    "
                                  >
                                    {{ $t('unassign') }}
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-menu>
                          </div>
                        </v-col>
                        <v-col cols="8" class="ml-n13">
                          <v-checkbox
                            v-model="subService.isChecked"
                            :label="subService.name"
                            @change="resetValidations"
                            v-if="!subService.id.includes('other')"
                            hide-details
                            :disabled="
                              isSubServiceAssignedToWorker(
                                service,
                                subService.id
                              )
                            "
                          >
                          </v-checkbox>

                          <v-row
                            v-if="
                              subService.id === 'other' ||
                                subService.id === 'other1' ||
                                isFirstOther(service.subServices, subService.id)
                            "
                            style="margin-bottom: 0.5%; margin-top: 1%"
                          >
                            <p
                              style="margin-left:1%; margin-top:1.5%"
                              class="font-weight-bold"
                            >
                              {{ $t('moreServices') }}
                            </p>
                            <v-icon
                              x-large
                              color="primary"
                              style="margin-left: 0.6%;margin-right: 0.5%"
                              @click="
                                pushNewOtherInService(service.subServices)
                              "
                            >
                              mdi-plus-box
                            </v-icon>

                            <v-icon
                              x-large
                              color="primary"
                              @click="deleteOtherInService(service.subServices)"
                            >
                              mdi-minus-box
                            </v-icon>
                          </v-row>

                          <v-text-field
                            v-model="subService.name"
                            :label="$t('other')"
                            v-if="subService.id.includes('other')"
                            :disabled="
                              isSubServiceAssignedToWorker(
                                service,
                                subService.id
                              )
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="py-0 mt-3">
                          <v-row
                            v-if="
                              subService.id === 'other' ||
                                subService.id === 'other1' ||
                                isFirstOther(service.subServices, subService.id)
                            "
                            style="margin-top: 20%;"
                          >
                          </v-row>
                          <v-text-field
                            :label="$t('price')"
                            v-model="subService.price"
                            :disabled="subService.fixedPrice"
                            :rules="[
                              value =>
                                isValidPriceForSubService(subService, value) ||
                                $t('priceIsRequiredForChosenSubService'),
                              value =>
                                isPositivePriceForSubService(
                                  subService,
                                  value
                                ) ||
                                $t('priceMustBePositiveForChosenSubService')
                            ]"
                            required
                            type="number"
                            class="pl-12 ma-0"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-container>
                </v-sheet>
              </v-sheet>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-sheet>
    <ConfirmationModal
      ref="confirmationModal"
      @confirm-action="unassignWorkers()"
    />
  </v-expansion-panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ConfirmationModal from '@/components/reusable-components/confirmation-modal/ConfirmationModal.vue';
import { CLIENT_API_URL } from '../../../../../constants/Environment';
import axios from 'axios';

export default {
  name: 'ServicesSection',

  components: { ConfirmationModal },

  props: ['sectionName'],

  data() {
    return {
      isValid: false,
      servicesToBeSelected: [],
      assignedServices: [],
      assignedServicesToBeSaved: [],
      orderNumber: null,
      counter: 1,
      orderId: ''
    };
  },

  created() {
    this.retrieveAllServices();
    this.retrieveAllWorkers();
  },

  watch: {
    allServices: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setAllServicesToServicesToBeSelected();
        }
      },
      deep: true
    },

    isValid: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('validated-service-data', this.isValid);
        }
      }
    }
  },

  computed: {
    ...mapGetters('services', ['allServices']),
    ...mapGetters('workers', ['allWorkers'])
  },

  methods: {
    ...mapActions('services', ['retrieveAllServices']),
    ...mapActions('workers', ['retrieveAllWorkers']),

    setAllServicesToServicesToBeSelected() {
      this.servicesToBeSelected = this.allServices.map(s => {
        if (s.subServices !== null) {
          return {
            ...s,
            fixedOtherQuantity: 1,
            subServices: s.subServices.map(ss => ({
              ...ss,
              isFinished: false,
              isChecked: false
            })),
            isChecked: false
          };
        } else {
          return {
            ...s,
            fixedOtherQuantity: 1,
            subServices: [],
            isChecked: false,
            isFinished: false
          };
        }
      });

      this.servicesToBeSelected.forEach(s => ({
        ...s,
        subServices: s.subServices.push({
          id: 'other1',
          name: '',
          price: null,
          quantity: 1,
          isFinished: false,
          isChecked: false
        })
      }));
    },

    pushNewOtherInService(subServicesOfService) {
      let counter = subServicesOfService.filter(obj => {
        return obj.id.includes('other');
      }).length;

      subServicesOfService.push({
        id: 'other' + (counter + 1),
        name: '',
        price: null,
        quantity: 1,
        isFinished: false,
        isChecked: false
      });
    },

    isFirstOther(subServicesOfService, subServiceId) {
      let firstotherSubService = subServicesOfService.find(ss =>
        ss.id.includes('other')
      );

      if (firstotherSubService.id === subServiceId) {
        return true;
      } else {
        return false;
      }
    },

    deleteOtherInService(subServicesOfService) {
      let counter = subServicesOfService.filter(obj => {
        return obj.id.includes('other');
      }).length;

      let lastOtherSubService = subServicesOfService.findLast(ss =>
        ss.id.includes('other')
      );

      if (counter !== 1 && !lastOtherSubService.isFinished) {
        let index = subServicesOfService.findIndex(
          ss => ss.id === 'other' + counter
        );
        subServicesOfService.splice(index, 1);
      }
    },

    getTotalSubService() {
      let total = 0;
      this.servicesToBeSelected.forEach(s => {
        if (s.isChecked) {
          if (s.subServices !== null) {
            if (s.subServices.length === 1) total += Number(s.price);
            s.subServices.forEach(ss => {
              if (ss.isChecked || (ss.id.includes('other') && ss.name !== ''))
                total += Number(ss.price);
            });
          }
        }
      });
      return total;
    },

    sendServicesData() {
      return this.servicesToBeSelected.slice();
    },

    sendAssignedServicesData() {
      return this.assignedServices.slice();
    },

    cleanSection() {
      this.resetValidations();
      this.setAllServicesToServicesToBeSelected();
      this.assignedServices = [];
      this.orderNumber = null;
      this.assignedServicesToBeSaved = [];
    },

    setServicesDataToEdit(services, assignedServices, orderNumber, id) {
      this.orderNumber = orderNumber;
      this.orderId = id;
      this.assignedServices = assignedServices;
      this.servicesToBeSelected.forEach(ps => {
        let foundService = services.find(p => p.id === ps.id);
        if (foundService) {
          ps.isChecked = true;
          ps.price = Number(foundService.price);
          ps.subServices.forEach(pssp => {
            ps.isFinished = foundService.isFinished;
            services.forEach(p => {
              let foundSubService = p.subServices.find(
                sp => sp.id === pssp.id && !pssp.id.includes('other')
              );
              if (foundSubService) {
                pssp.isChecked = true;
                pssp.price = Number(foundSubService.price);
                pssp.isFinished = foundSubService.isFinished;
              }
            });
          });

          var firstotherSubService;
          var otherSubServices;
          services.forEach(s => {
            firstotherSubService = s.subServices.find(ss =>
              ss.id.includes('other')
            );
            otherSubServices = s.subServices.filter(ss =>
              ss.id.includes('other')
            );
            otherSubServices.shift();

            if (firstotherSubService && s.name === ps.name) {
              ps.subServices.splice(ps.subServices.length - 1, 1, {
                ...firstotherSubService,
                price: Number(firstotherSubService.price),
                isChecked: false,
                isFinished: firstotherSubService.isFinished
              });
              if (otherSubServices.length >= 1) {
                otherSubServices.forEach(ss => {
                  ps.subServices.push({
                    ...ss,
                    price: Number(ss.price),
                    isChecked: false,
                    isFinished: ss.isFinished
                  });
                });
              }
            }
          });
        }
      });
    },

    getSectionStyle() {
      return {
        '--window-height': window.innerHeight + 'px',
        '--window-width': window.innerWidth + 'px',
        '--sections-height': 340 + 'px'
      };
    },

    resetValidations() {
      this.$refs.servicesSectionForm.resetValidation();
    },

    isValidPriceForSubService(subService, value) {
      if (
        subService.isChecked ||
        (subService.id.includes('other') && subService.name !== '')
      ) {
        if (value === '') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    isPositivePriceForSubService(subService, value) {
      if (
        subService.isChecked ||
        (subService.id.includes('other') && subService.name !== '')
      ) {
        if (value < 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    isValidPriceForService(service, value) {
      if (service.isChecked) {
        if (value === '') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    isPositivePriceForService(service, value) {
      if (service.isChecked) {
        if (value < 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    isServiceAssignedToWorker(service) {
      let foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      if (service.subServices.length === 1) {
        if (foundAssignServices.find(fs => fs.serviceId === service.id)) {
          return true;
        } else {
          return false;
        }
      } else {
        let assginedSubServicesCounter = 0;
        foundAssignServices.forEach(fas => {
          service.subServices.forEach(ss => {
            if (fas.subServiceId === ss.id) {
              assginedSubServicesCounter++;
            }
          });
        });
        if (assginedSubServicesCounter > 0) {
          return true;
        } else {
          return false;
        }
      }
    },

    isSubServiceAssignedToWorker(service, subServiceId) {
      let foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      if (foundAssignServices.find(fs => fs.subServiceId === subServiceId)) {
        return true;
      } else {
        return false;
      }
    },

    openConfirmationModalForService(service) {
      this.assignedServicesToBeSaved = [];
      let message;
      let foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      if (service.subServices.length === 1) {
        let foundAssign = foundAssignServices.find(
          fas => fas.serviceId === service.id
        );
        let worker = this.allWorkers.find(
          worker => worker.id === foundAssign.workerId
        );
        message = `${this.$t('youAreAboutToUnassignTheJob')}: ${
          service.name
        } ${this.$t('thatWasInChargeOfTheWorker')}: ${worker.name} ${this.$t(
          'inTheOrder'
        )}: ${this.orderNumber}. ${this.$t('areYouSure')}`;
      } else {
        message = `${this.$t('youAreAboutToUnassignAllTheJobsFrom')}: ${
          service.name
        } ${this.$t('inTheOrder')}: ${this.orderNumber}. ${this.$t(
          'areYouSure'
        )}`;
      }
      this.assignedServicesToBeSaved = this.assignedServices.filter(
        as => as.serviceId !== service.id
      );
      this.$refs.confirmationModal.open(
        Object.assign(
          { message: message },
          { isOverOtherModal: true },
          { title: this.$t('attention') }
        )
      );
    },

    openConfirmationModalForSubService(service, subServiceId) {
      this.assignedServicesToBeSaved = [];
      let message;
      let foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      let subService = service.subServices.find(
        subService => subService.id === subServiceId
      );
      let foundAssign = foundAssignServices.find(
        fas => fas.subServiceId === subServiceId
      );
      let worker = this.allWorkers.find(
        worker => worker.id === foundAssign.workerId
      );
      message = `${this.$t('youAreAboutToUnassignTheJob')}: ${service.name}(${
        subService.name
      }) ${this.$t('thatWasInChargeOfTheWorker')}: ${worker.name} ${this.$t(
        'inTheOrder'
      )}: ${this.orderNumber}. ${this.$t('areYouSure')}`;
      this.assignedServicesToBeSaved = this.assignedServices.filter(
        as => as.subServiceId !== foundAssign.subServiceId
      );
      this.$refs.confirmationModal.open(
        Object.assign(
          { message: message },
          { isOverOtherModal: true },
          { title: this.$t('attention') }
        )
      );
    },

    async unassignWorkers() {
      this.assignedServices = this.assignedServicesToBeSaved;
      const orderId = this.orderId;
      const url = `${CLIENT_API_URL}/orders/${orderId}/assign-services`;
      await axios.put(url, this.assignedServices);
    },

    isFinishedAssignedService(service) {
      if (service.subServices.length === 1) {
        if (service.isFinished) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          service.subServices.some(subService => subService.isFinished === true)
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    isFinishedAssignedSubService(service, subServiceId) {
      let foundSubService = service.subServices.find(
        ss => ss.id === subServiceId
      );
      if (foundSubService.isFinished) {
        return true;
      } else {
        return false;
      }
    },

    getFinishedJobMessageForService(service) {
      let foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      if (service.subServices.length === 1) {
        let foundAssign = foundAssignServices.find(
          fas => fas.serviceId === service.id
        );
        let worker = this.allWorkers.find(
          worker => worker.id === foundAssign.workerId
        );
        return `${this.$t('thisWorkWasFinishedBy')}: ${worker.name}, ${this.$t(
          'youHaveToReOpenItToUnassignIt'
        )}.`;
      } else {
        return `${this.$t('youCanNotUnassignAllTheJobsFrom')}: ${
          service.name
        }, ${this.$t('becauseSomeOfThemAreAlreadyFinished')}.`;
      }
    },

    getFinishedJobMessageForSubService(service, subServiceId) {
      let foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      let foundAssign = foundAssignServices.find(
        fas => fas.subServiceId === subServiceId
      );
      let worker = this.allWorkers.find(
        worker => worker.id === foundAssign.workerId
      );
      return `${this.$t('thisWorkWasFinishedBy')}: ${worker.name}, ${this.$t(
        'youHaveToReOpenItToUnassignIt'
      )}.`;
    }
  }
};
</script>
